import { Component, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Const } from '@const/Const';
import { DialogService } from '@dialogs/dialog.service';
import { ResponseAdminOrderDetail } from "@wearewarp/types/rest-api/admin";
import { FormDataShipmentItem, OrderUpdateFormId } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { EditShipmentEntryAddRemoveStops } from '../../edit-shipment/add-remove-stops';
import { Utils } from '@services/utils';
import { ShipmentEntryGroupTasksService } from './group-tasks-service';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import { MasterData } from '@services/master.data';
import { ActivatedRoute } from '@angular/router';
import { RefreshRouteLocationForMapService } from './refresh-route-locations-service';
import { EditShipmentEntryLocation } from '../../edit-shipment/location-address';
import { EditShipmentEntryServiceOption } from '../../edit-shipment/service-option';
import { EditShipmentEntryContactInfo } from '../../edit-shipment/contact-info';
import { EditShipmentEntryReferenceNumber } from '../../edit-shipment/reference-number';
import { EditShipmentEntryTextField, EditShipmentEntryTextFieldKeys } from '../../edit-shipment/access-code';
import { ShipmentEntrySelectEditMode } from '../../edit-shipment/add-remove-stops/select-edit-mode';
import { FormDataEditLocationAddress } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { ModelOrderDetailLocation } from '@app/interfaces/order';
import { ContactType, TaskType } from '@wearewarp/types';
import { Log } from '@services/log';
import { EditShipmentEntryItems } from '../../edit-shipment/items';
import { FormDataOrderItems } from '@app/admin/shipment-entry/interface';
import { InputHelper } from '@services/input-helper';
import { ShipmentEntryDeliveryInfoSettings } from './components/delivery-info-setting';
import { BookAppointment, FormDataBookAppointment, FormDataUpdateTimeWindows, ModalHelper, UpdateTimeWindows } from '@wearewarp/ng-antd';
import { LatLng } from '@wearewarp/types/data-model';

@Component({
  selector: 'route-detail-batch',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class RouteDetailBatch extends BaseComponent {

  @ViewChild('noteContent') noteContent: TemplateRef<any>;

  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  private _model: ResponseAdminOrderDetail;
  @Input() get model() { return this._model };
  set model(value) {
    this._model = value;
    this.extractDataModel(value);
  }
  @Input() isTurnOnEditMode = Const.defaultTurnOnEditMode;

  locations: ModelOrderDetailLocation[] = []
  job;
  shipmentWarpIdGoToDispatch;
  clearAppoinmentLoading = false
  get hasRoute() {
    return this.job ? true : false
  }

  get orderId() {
    return this.model.id;
  }

  constructor(
    private modalHelper: ModalHelper,
    protected activatedRoute: ActivatedRoute,
    private refreshRouteLocationForMapService: RefreshRouteLocationForMapService,
    ){
    super(activatedRoute);
  }

  ngOnInit(): void {
  }

  extractDataModel(data: ResponseAdminOrderDetail) {
    if (!data) {
      return;
    }
    const shipments = data.metadata?.shipments || [];
    let deliveryInfos = [];
    for (let shipment of shipments) {
      let infos = shipment.deliveryInfos || [];
      deliveryInfos = [ ...deliveryInfos, ...infos ];
      this.shipmentWarpIdGoToDispatch = shipment.warpId;
    }
    const job = shipments?.[0]?.jobs?.[0];
    if (job) {
      this.job = job;
    }
    if (Utils.isArrayNotEmpty(job?.tasks)) {
      // Nếu tồn tại job thì lấy list location theo tasks
      this.locations = ShipmentEntryGroupTasksService.getListLocationFollowJobTasks(data);
    } else if (Utils.isArrayNotEmpty(data.sortedTaskIds)){
      // Nếu ko thì kiểm tra xem có sortTaskIds ko thì sắp xếp theo
      this.locations = ShipmentEntryGroupTasksService.getListLocationFollowSortedTaskIds(data);
    } else {
      // Nếu ko thì hiển thị pickup, dropoff (ko quan tâm thứ tự)
      this.locations = ShipmentEntryGroupTasksService.getListLocation_NotNeedSort(data);
    }
    Log.d('RouteDetailBatch locations: ', this.locations);
    this.refreshRouteLocationForMapService.changeData(this.locations);
    this.getEstimateTrafficForEachStops(this.locations);
  }

  isPickupLocation(location) {
    return location.type == Const.TaskType.PICKUP;
  }
  isDropoffLocation(location) {
    return location.type == Const.TaskType.DROPOFF;
  }
  public get naText(){
    return "N/A"
  }

  shouldShowShipmentWarpId(location: ModelOrderDetailLocation) {
    return this.model.shipmentEntryMode == Const.ShipmentEntryMode.multiDrop && location.type == Const.TaskType.DROPOFF ||
            this.model.shipmentEntryMode == Const.ShipmentEntryMode.multiPick && location.type == Const.TaskType.PICKUP ||
            this.model.shipmentEntryMode == Const.ShipmentEntryMode.multiPickDrop;
  }

  getTimePeriods(location){
    let timeWindowsText = location?.windows?.map((window) =>
      DateUtil.displayTimeWindow(window, {
        timezone: location?.addr?.metadata?.timeZoneStandard,
        format: "MM/DD/YYYY h:mm A",
      })
    )?.join('<br/>');
    return timeWindowsText || this.naText;
  }

  getServiceOptions(location) {
    const serviceOptions = (location?.serviceOptions || [])?.map(sopt => MasterData.getServiceOptionName(sopt)).join(', ');
    return serviceOptions || this.naText;
  }
  getPrimaryContact(location){
    return (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.fullName || this.naText;
  }
  getPrimaryPhoneNumber(location) {
    const phoneNumber = (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.phone;
    return phoneNumber ? InputHelper.formatPhone(phoneNumber) : this.naText;
  }
  getPhoneExtension(location) {
    const phoneExtension = (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.phoneExtension;
    return phoneExtension ? `Ext ${phoneExtension}` : '';
  }
  getPrimaryEmail(location) {
    return (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.primary)?.[0]?.email || this.naText;
  }

  getSecondaryContact(location){
    return (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.fullName || this.naText;
  }
  getSecondaryPhoneNumber(location) {
    const phoneNumber = (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.phone;
    return phoneNumber ? InputHelper.formatPhone(phoneNumber) : this.naText;
  }
  getSecondaryPhoneExtension(location) {
    const phoneExtension = (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.phoneExtension;
    return phoneExtension ? `Ext ${phoneExtension}` : '';
  }
  getSecondaryEmail(location) {
    return (location?.contacts || [])?.filter(it => it?.type == Const.ContactType.secondary)?.[0]?.email || this.naText;
  }

  getReferenceNo(location){
    return (location?.refNums || [])?.join(', ') || this.naText;
  }

  getAccessCode(location){
    return location?.accessCode || this.naText;
  }

  getInstructions(location){
    return location?.instructions || this.naText;
  }

  getNotes(location){
    return location?.note || this.naText;
  }

  isNotCurrentRoute(route){
    return !route?.isCurrent
  }

  viewPOD(job){
    this.comingSoon();
    // TODO
  }

  viewNotes(job){
    this.comingSoon();
    // TODO
  }

  get isSingleMode(){
    return this.model?.shipmentEntryMode == Const.ShipmentEntryMode.single;
  }

  get isMultiDropMode(){
    return this.model?.shipmentEntryMode == Const.ShipmentEntryMode.multiDrop;
  }

  get isMultiPickMode(){
    return this.model?.shipmentEntryMode == Const.ShipmentEntryMode.multiPick;
  }

  get isMultiPickDropMode(){
    return this.model?.shipmentEntryMode == Const.ShipmentEntryMode.multiPickDrop;
  }

  getShipmentWarpIdForLocation(location: ModelOrderDetailLocation) {
    if (location.shipmentWarpIds.length == 1) {
      return this.showShipmentWarpId(location.shipmentWarpIds[0]);
    } else if (location.shipmentWarpIds.length > 1) {
      return `${this.showShipmentWarpId(location.shipmentWarpIds[0])}(+${location.shipmentWarpIds.length - 1})`;
    } else {
      return '';
    }
  }

  shouldShowItemsOfLocation(location: ModelOrderDetailLocation) {
    if (this.isMultiDropMode && location.type == Const.TaskType.DROPOFF) return true;
    if (this.isMultiPickMode && location.type == Const.TaskType.PICKUP) return true;
    if (this.isMultiPickDropMode && location.type == Const.TaskType.DROPOFF) return true;
    return false;
  }

  isAppointmentRequired(location: ModelOrderDetailLocation) {
    return location.requiresAppointment == true || location.appointmentInfo?.from;
  }

  hasAppointmentTime(location){
    return location?.appointmentInfo?.from || location?.appointmentInfo?.to
  }

  clearAppointmentTime(location){
    this.clearAppoinmentLoading = true
    this.confirmDeletion({
      message: 'Are you sure you want to delete the appointment time?',
      fnOk: () => {
        this.updateForm('location-appointment', location, {appointmentInfo: null}).subscribe((res) => {
          this.refreshDetailOrder.emit()
          this.clearAppoinmentLoading = false
        }, (e) => {
          this.clearAppoinmentLoading = false
        })
      },
      fnCancel: () => {
        this.clearAppoinmentLoading = false
      }
    })
  }

  getAppointmentText(location) {
    if (!Utils.isObjectNotEmpty(location?.appointmentInfo)) {
      return 'Requires Appointment'
    } else {
      const text = DateUtil.displayTimeWindow(location.appointmentInfo, {
        timezone: location?.addr?.metadata?.timeZoneStandard,
        format: "MM/DD/YYYY h:mm A",
      });
      if (!text) return 'Requires Appointment'
      return text + ' (Appointment Scheduled)';
    }
  }

  hasWindowsTime(location: ModelOrderDetailLocation) {
    return location.windows?.length > 0;
  }

  get canAddAndRemoveStop() {
    if (this.model?.shipmentType != Const.ShipmentTypes.fullTruckLoad) return false;
    return true;
  }

  onBtnAddRemoveStops() {
    switch (this.model?.shipmentEntryMode) {
      case Const.ShipmentEntryMode.single:
        DialogService.openDialog(ShipmentEntrySelectEditMode, {
          replaceWrapClassName: true,
          nzClassName: '',
          nzCentered: true,
          nzComponentParams: {
            modelOrder: this.model,
            onOk: () => { this.refreshDetailOrder.emit()}
          }
        });
        break;
      case Const.ShipmentEntryMode.multiPick:
        DialogService.openDialog(EditShipmentEntryAddRemoveStops, {
          nzMaskClosable: false,
          nzComponentParams: {
            currentShipmentEntryMode: Const.ShipmentEntryMode.multiPick,
            targetShipmentEntryMode: Const.ShipmentEntryMode.multiPick,
            modelOrder: this.model,
            onOk: () => { this.refreshDetailOrder.emit() }
          },
          nzClassName: "modal-xxl",
          nzWrapClassName: 'shipment-entry-add-remove-stops-dlg'
        });
        break;
      case Const.ShipmentEntryMode.multiDrop:
        DialogService.openDialog(EditShipmentEntryAddRemoveStops, {
          nzMaskClosable: false,
          nzComponentParams: {
            currentShipmentEntryMode: Const.ShipmentEntryMode.multiDrop,
            targetShipmentEntryMode: Const.ShipmentEntryMode.multiDrop,
            modelOrder: this.model,
            onOk: () => { this.refreshDetailOrder.emit() }
          },
          nzClassName: "modal-xxl",
          nzWrapClassName: 'shipment-entry-add-remove-stops-dlg'
        });
        break;
      case Const.ShipmentEntryMode.multiPickDrop:
        DialogService.openDialog(EditShipmentEntryAddRemoveStops, {
          nzMaskClosable: false,
          nzComponentParams: {
            currentShipmentEntryMode: Const.ShipmentEntryMode.multiPickDrop,
            targetShipmentEntryMode: Const.ShipmentEntryMode.multiPickDrop,
            modelOrder: this.model,
            onOk: () => { this.refreshDetailOrder.emit() }
          },
          nzClassName: "modal-xxl",
          nzWrapClassName: 'shipment-entry-add-remove-stops-dlg'
        });
        break;
    }
  }

  private updateForm(formId: OrderUpdateFormId, location: ModelOrderDetailLocation, data: any) {
    Log.d(`updateForm ${formId} for ${location.type}, data: `, data);
    const shipmentIds = location.shipmentIds || [];
    const deliveryIds = location.deliveryIds || [];
    const locationType = location.type;
    const url = Const.APIV2(`${Const.APIURI_ORDERS}/${this.orderId}/${formId}`);
    return this.api.PUT(url, {...data, shipmentIds, deliveryIds, locationType});
  }

  private locationType(type: TaskType) {
    return type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
  }

  onBtnEditLocation(location: ModelOrderDetailLocation) {
    const formDataModel: FormDataEditLocationAddress = {
      locationName: location.locationName,
      addr: location.addr,
      warehouseId: location.warehouseId,
    }
    DialogService.openFormDialog1(EditShipmentEntryLocation, {
      nzAutofocus: null,
      nzComponentParams: {
        headerText: `${this.locationType(location.type)} Location`,
        type: location.type,
        model: formDataModel,
        closeOnSuccess: true,
        onSave: data => this.updateForm('location-address', location, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal",
    });
  }

  onBtnEditWindowsTime(location: ModelOrderDetailLocation) {
    UpdateTimeWindows.openModal(this.modalHelper, {
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: resp => this.refreshDetailOrder.emit(),
      nzTitle: `${this.locationType(location.type)} Time Windows`,
      nzComponentParams: {
        timezone: location.addr?.metadata?.timeZoneStandard,
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        model: {
          windows: location.windows,
          reasonCodeId: location.reasonCodeId
        },
        submit: (data: FormDataUpdateTimeWindows) => this.updateForm('location-windows', location, data)
      }
    });
  }

  onBtnEditBookAppointment(location) {
    const appointmentInfo = location?.appointmentInfo || {};
    BookAppointment.openModal(this.modalHelper, {
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: resp => this.refreshDetailOrder.emit(),
      nzTitle: `${this.locationType(location.type)} Appointment`,
      nzComponentParams: {
        timezone: location.addr?.metadata?.timeZoneStandard,
        reasonCodes: MasterData.getChangeDateTimeReasons(),
        model: {
          appointmentInfo,
          reasonCodeId: location.reasonCodeId,
        },
        submit: (data: FormDataBookAppointment) => this.updateForm('location-appointment', location, data)
      },
    });
  }

  onBtnEditServiceoption(location: ModelOrderDetailLocation) {
    const serviceOptions = location.serviceOptions;
    DialogService.openFormDialog1(EditShipmentEntryServiceOption, {
      nzComponentParams: {
        headerText: `${this.locationType(location.type)} Service Options`,
        model: {serviceOptions},
        type: location.type,
        closeOnSuccess: true,
        onSave: data => this.updateForm('location-service-options', location, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal",
    });
  }

  onBtnEditContactInfo(location, contactType: ContactType) {
    const contacts = location?.contacts || [];
    let newContacts = [...contacts];
    let indexExist = -1;
    let contactInfo;
    for (let i=0;i<contacts.length;i++) {
      if (contacts[i]?.type == contactType) {
        contactInfo = contacts[i];
        indexExist = i;
        break;
      }
    }
    DialogService.openFormDialog1(EditShipmentEntryContactInfo, {
      nzComponentParams: {
        headerText: `${this.locationType(location.type)} ${Utils.capitalizeFirstLetter(contactType)} Contact`,
        model: contactInfo,
        contactType: contactType,
        closeOnSuccess: true,
        onSave: data => {
          if (indexExist === -1) {
            newContacts = newContacts.concat([{...data, type: contactType}]);
          } else {
            newContacts[indexExist] = {...data, type: contactType};
          }
          newContacts = newContacts.filter(x=>x);
          return this.updateForm('location-contact-info', location, { contacts: newContacts })
        },
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal",
    });
  }

  onBtnEditReferenceNumber(location: ModelOrderDetailLocation) {
    let refNums = location.refNums;
    DialogService.openFormDialog1(EditShipmentEntryReferenceNumber, {
      nzComponentParams: {
        headerText: `${this.locationType(location.type)} Reference Numbers`,
        type: location.type,
        model: {refNums},
        closeOnSuccess: true,
        onSave: data => this.updateForm('location-refnums', location, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal",
    });
  }

  onBtnEditAccessCode(location: ModelOrderDetailLocation) {
    this.editTextField(location, 'accessCode');
  }

  onBtnEditInstruction(location: ModelOrderDetailLocation) {
    this.editTextField(location, 'instructions');
  }

  onBtnEditNote(location: ModelOrderDetailLocation) {
    this.editTextField(location, 'note');
  }

  private editTextField(location: ModelOrderDetailLocation, key: EditShipmentEntryTextFieldKeys) {
    DialogService.openFormDialog1(EditShipmentEntryTextField, {
      nzComponentParams: {
        keys: [key],
        model: {[key]: location[key]},
        isFieldMultiLine: (key: EditShipmentEntryTextFieldKeys) => {
          switch (key) {
            case 'accessCode':
              return false;
            case 'instructions':
            case 'note':
              return true;
          }
        },
        getFieldLabel(key) {
          const prefix = location.type == Const.TaskType.PICKUP ? 'Pickup' : 'Delivery';
          switch (key) {
            case 'accessCode': return `${prefix} Access Code`;
            case 'instructions': return `${prefix} Instructions (External)`;
            case 'note': return `${prefix} Note (Internal)`;
          }
        },
        onSave: (data) => this.updateForm(`location-${key}`, location, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal",
    });
  }

  onBtnEditItemsOrderSingle() {
    const shipment = this.model?.metadata?.shipments?.[0];
    if (!shipment) return;
    const shipmentId = shipment.id;
    DialogService.openDialog(EditShipmentEntryItems, {
      nzComponentParams: {
        shipmentWarpId: shipment.warpId,
        modelItems: this.model?.items,
        onSave: data => this.saveItems(shipmentId, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal-xxl",
    });
  }

  onBtnEditItemsLocation(location: ModelOrderDetailLocation) {
    const shipmentIds = location.shipmentIds || [];
    if (!Utils.isArrayNotEmpty(shipmentIds)) return;
    const shipmentId = shipmentIds[0];
    DialogService.openDialog(EditShipmentEntryItems, {
      nzComponentParams: {
        shipmentWarpId: location.shipmentWarpIds[0],
        modelItems: location.items,
        onSave: data => this.saveItems(shipmentId, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal-xxl",
    });
  }

  private saveItems(shipmentId, data: FormDataOrderItems) {
    const url = Const.APIV2(`${Const.APIURI_SHIPMENTS}/${shipmentId}/items`);
    return this.api.PUT(url, data);
  }

  onBtnChangeDeliveryInfoSettings(location) {
    DialogService.openFormDialog1(ShipmentEntryDeliveryInfoSettings, {
      nzComponentParams: {
        headerText: `${this.locationType(location.type)} ${this.getShipmentWarpIdForLocation(location)} Settings`,
        model: { settings: location.settings },
        onSave: data => this.updateForm('location-settings', location, data),
        onRefreshDetailOrder: () => this.refreshDetailOrder.emit()
      },
      nzClassName: "modal",
    });
  }

  appointmentRequireChanged(event) {
    
  }

  public displayInfoTrafficForEachStops = [];
  private processLatLong(address) {
    const latitude = address?.metadata?.latitude || address?.location?.latitude;
    const longitude = address?.metadata?.longitude || address?.location?.longitude;
    return { latitude, longitude };
  }

  getEstimateTrafficForEachStops(locations) {
    if (!Utils.isArrayNotEmpty(locations)) return;
    const locationsLatLong: LatLng[] = [];
    for (let index = 0; index < locations.length; index++) {
      const info = locations[index];
      const { latitude, longitude } = this.processLatLong(info?.addr);
      if (latitude && longitude) {
        locationsLatLong.push({
          latitude,
          longitude,
        });
      }
    }
    let url = `${Const.APIURI_ORDERS_V4}/traffic-each-stops`;
    this.api.POST(url, { locations: locationsLatLong }).subscribe(
      (resp) => {
        if (resp?.data?.costs?.length == locationsLatLong.length -1) {
          this.displayInfoTrafficForEachStops = [{}];
          for (let item of resp?.data?.costs || []) {
            this.displayInfoTrafficForEachStops.push({
              mileage: this.getRouteMileage(item),
              hours: this.getRouteHours(item)
            });
          }
        }
      },
      (err) => {}
    );
  }

  private getRouteMileage(data) {
    if (!data) return 'N/A'
    const totalDistance = data.distance;
    if (!totalDistance) return 'N/A'
    return (totalDistance / 1609.34).toFixed(2).toLocaleString()
  }

  private getRouteHours(data) {
    if (!data) return 'N/A'
    const totalTime = data.time;
    if (!totalTime) return 'N/A'
    return (totalTime / 3600.00).toFixed(2).toLocaleString()
  }

  public isWarningAddress(location) {
    return Utils.isObjectNotEmpty(location?.addr?.metadata) ? false : true;
  }
}
