<div style="padding: 20px 20px 0px 20px">
  <div class="title flex">
    <div>Order/Parent Revenue</div>
    <div class="left20">
      <ng-template #indicatorTemplate></ng-template>
      <nz-spin [nzSpinning]="isLoading" [nzIndicator]="indicatorTemplate">
        <label nz-checkbox (ngModelChange)="onBtnDoNotInvoiceChange($event)" [(ngModel)]="isDoNotInvoice">
          Do not invoice
        </label>
      </nz-spin>
    </div>
  </div>
</div>

<div *ngIf="hasData">
  <ng-container *ngFor="let revenueInfo of revenueInfos; let i = index;">
    <div class="revenue-item" style="padding: 20px 20px 0px 20px;">
      <span *ngIf="revenueInfo.clientId">
        <span class="text-medium">{{getCustomerName(revenueInfo.clientId)}}</span>
        <span *ngIf="isTurnOnEditMode">
          <a class="edit-item-shipment-entry" (click)="onBtnEditRevenue(revenueInfo)">
            <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
          </a>
        </span>
        <button *ngIf="isCheckDisibleEditRevenue(model)"
          nz-button (click)="onBtnGenerateAdditionalInvoice(revenueInfo)">Generate additional invoice</button>
      </span>
      <div *ngIf="invoice" class="flex top10 bottom15">
        <div class="medium">Invoice :</div>
        <div class="left10">
          <a (click)="downloadInvoice({invoice})">
            <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
              class="right10"></i>
            <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
              class="right10"></i>Invoice
          </a>
        </div>
        <div class="grey left10 f13" style="font-style: italic">
          (<warp-when-by [model]="invoice?.insert" [viewTemplate]="tplInvoiceCreateWhenBy"></warp-when-by>,
            <warp-when-by [model]="invoiceSent" [viewTemplate]="tplInvoiceSentWhenBy"></warp-when-by>)
        </div>
      </div>
      <ng-container *ngIf="revenueInfo?.isUseCostOrder">
        <ng-container *ngTemplateOutlet="tplRevenue; context: { cost: revenueInfo.costOrder }"></ng-container>
      </ng-container>
      <ng-container *ngIf="!revenueInfo?.isUseCostOrder">
        <div *ngFor="let costShipment of revenueInfo.costShipments">
          <div class="font-medium top10">Shipment {{costShipment.shipmentWarpId}}</div>
          <ng-container *ngTemplateOutlet="tplRevenue; context: {cost: costShipment.cost}"></ng-container>
        </div>
      </ng-container>
    </div>

    <div style="padding: 0px 20px 20px 20px;">
      <ng-container *ngIf="allocations?.length">
        <nz-divider></nz-divider>
        <div class="text-medium bottom10">Allocation</div>
        <div class="bottom10" nz-row [nzGutter]="{ sm: 16 }">
          <div nz-col nzSm="6" nzMd="6">Shipment</div>
          <div nz-col nzSm="6" nzMd="6">Revenue</div>
          <div nz-col nzSm="6" nzMd="6">Cost</div>
        </div>
        <div class="bottom5" nz-row [nzGutter]="{ sm: 16 }" *ngFor="let allocation of allocations">
          <div nz-col nzSm="6" nzMd="6">
            <span>{{ allocation.id }}</span>
          </div>
          <div nz-col nzSm="6" nzMd="6">
            <span *ngIf="allocation.revenue">$<span decimal [value]="allocation.revenue"></span></span>
          </div>
          <div nz-col nzSm="6" nzMd="6">
            <span *ngIf="allocation.cost">$<span decimal [value]="allocation.cost"></span></span>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="additionalCosts.length">
      <div *ngFor="let additionalCost of additionalCosts; let i = index">
        <div style="height: 20px;background-color: #f0f2f5;"></div>
        <div style="padding: 20px;">
          <div class="flex">
            <div class="medium">Additional Invoice :</div>
            <div class="left10">
              <a (click)="downloadInvoice(additionalCost)">
                <i *ngIf="!additionalCost.invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                  class="right10"></i>
                <i *ngIf="additionalCost.invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                  class="right10"></i>Invoice {{ i + 2 }}
              </a>
            </div>
            <div *ngIf="!additionalCost.invoiceSent?.when" class="left10">
              <a class="left10" (click)="onBtnEditAdditionalInvoice(revenueInfo, additionalCost)">
                <i nz-icon nzType="edit" nzTheme="outline" class="right10"
                nz-tooltip nzTooltipTitle="Edit Invoice"></i>
              </a>
            </div>
            <div class="grey left10 f13" style="font-style: italic">
              (<warp-when-by [model]="additionalCost.invoice?.insert" [viewTemplate]="tplInvoiceCreateWhenBy"></warp-when-by>,
                <warp-when-by [model]="additionalCost.invoiceSent" [viewTemplate]="tplInvoiceSentWhenBy"></warp-when-by>)
            </div>
          </div>
          <nz-divider></nz-divider>
          <ng-container *ngTemplateOutlet="tplAdditionalCost; context: { cost: additionalCost.cost }"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #tplRevenue let-cost="cost">
  <div class="top10">
    <div nz-row [nzGutter]="{ sm: 16 }" class="bottom10">
      <div nz-col nzSm="6" nzMd="6">
        <span>Currency</span>
      </div>
      <div nz-col nzSm="6" nzMd="6">
        <span>{{cost.currency?.type || 'USD'}}</span>
      </div>
    </div>
    <div nz-row [nzGutter]="{ sm: 16 }" *ngIf="cost.currency?.type && cost.currency.type !== 'USD'"
      style="margin-bottom: 5px;">
      <div nz-col nzSm="6" nzMd="6">
        <span>FX Rate</span>
      </div>
      <div nz-col nzSm="6" nzMd="6">
        <span>{{cost.currency?.fxRate || 'N/A'}}</span>
      </div>
    </div>
    <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 5px;">
      <div nz-col nzSm="12" nzMd="12"></div>
      <div nz-col nzSm="4" nzMd="4">Rate</div>
      <div nz-col nzSm="4" nzMd="4">Quantity</div>
      <div nz-col nzSm="4" nzMd="4">Total</div>
    </div>
    <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;">
      <div nz-col nzSm="12" nzMd="12">Transit Cost</div>
      <div nz-col nzSm="4" nzMd="4">{{getTransitRate(cost)}}</div>
      <div nz-col nzSm="4" nzMd="4">{{cost.transitCost?.qty || ''}}</div>
      <div nz-col nzSm="4" nzMd="4">{{getTransitTotal(cost)}}</div>
    </div>
    <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;">
      <div nz-col nzSm="12" nzMd="12">
        <span>Volume Discount</span>
        <span style="margin-left: 5px;">({{getVolumeDiscountType(cost)}})</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <ng-container *ngIf="cost.volumeDiscount?.type == 'percentage'">
          <span>{{cost.volumeDiscount?.percentage || ''}}%</span>
        </ng-container>
        <ng-container *ngIf="cost.volumeDiscount?.type == 'flatRate'">
          <span>{{cost.volumeDiscount?.flatRate || ''}}</span>
        </ng-container>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <ng-container *ngIf="cost.volumeDiscount?.type == 'percentage'">
          <span>-</span>
        </ng-container>
        <ng-container *ngIf="cost.volumeDiscount?.type == 'flatRate'">
          <span>{{cost.volumeDiscount?.qty || ''}}</span>
        </ng-container>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{getDiscount(cost)}}</span>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;">
      <div nz-col nzSm="12" nzMd="12">
        <span class="semibold">Sub Total</span>
      </div>
      <div nz-col nzSm="4" nzMd="4" nzOffset="8">
        <span class="semibold">{{getSubTotal(cost)}}</span>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 15px;">
      <div nz-col nzSm="12" nzMd="12">
        <span>Fuel Cost</span>
        <span style="margin-left: 5px;">({{getFuelCostType(cost)}})</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <ng-container *ngIf="cost.fuelCost?.type == 'rpm'">
          <span>{{getFuelRate(cost)}}</span>
        </ng-container>
        <ng-container *ngIf="cost.fuelCost?.type == 'percentage'">
          <span>{{cost.fuelCost?.percentage || ''}}%</span>
        </ng-container>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <ng-container *ngIf="cost.fuelCost?.type == 'rpm'">
          <span>{{cost.fuelCost?.qty || ''}}</span>
        </ng-container>
        <ng-container *ngIf="cost.fuelCost?.type == 'percentage'">
          <span>-</span>
        </ng-container>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{getFuelRateTotal(cost)}}</span>
      </div>
    </div>
    <div class="separator h bottom10"></div>
    <div style="margin-bottom: 5px;">Service Options:</div>
    <div *ngFor="let item of cost.serviceOptions" nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 3px;">
      <div nz-col nzSm="12" nzMd="12">
        <span>{{getServiceOptionName(item._id)}}</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{getServiceRate(cost, item)}}</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{item.qty}}</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{getServiceOptionMoneyValue(cost, item)}}</span>
      </div>
    </div>
    <div class="separator h top10 bottom10"></div>
    <div nz-row [nzGutter]="{ sm: 16 }" style="margin-top: 15px;">
      <div nz-col nzSm="12" nzMd="12">
        <span class="grand-total">{{ labelTotal }}({{cost.currency?.type || 'USD'}}):</span>
      </div>
      <div nz-col nzSm="4" nzMd="4" nzOffset="8">
        <span class="grand-total">{{ getRevenueText(cost) }}</span>
      </div>
    </div>
    <ng-container *ngIf="cost.currency?.type && cost.currency.type !== 'USD'">
      <div class="separator h top10 bottom10"></div>
      <div nz-row [nzGutter]="{ sm: 16 }" style="margin-top: 15px;">
        <div nz-col nzSm="12" nzMd="12">
          <span class="grand-total">USD Conversion:</span>
        </div>
        <div nz-col nzSm="4" nzMd="4" nzOffset="8">
          <span class="grand-total">{{ getUsdConversionText(cost) }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #tplAdditionalCost let-cost="cost">
  <div>
    <div style="margin-bottom: 5px;">Service Options:</div>
    <div *ngFor="let item of cost.serviceOptions" nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 3px;">
      <div nz-col nzSm="12" nzMd="12">
        <span>{{getServiceOptionName(item._id)}}</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{getServiceRate(cost, item)}}</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{item.qty}}</span>
      </div>
      <div nz-col nzSm="4" nzMd="4">
        <span>{{getServiceOptionMoneyValue(cost, item)}}</span>
      </div>
    </div>
    <div class="separator h top10 bottom10"></div>
    <div nz-row [nzGutter]="{ sm: 16 }" style="margin-top: 15px;">
      <div nz-col nzSm="12" nzMd="12">
        <span class="grand-total">{{ labelTotal }}({{cost.currency?.type || 'USD'}}):</span>
      </div>
      <div nz-col nzSm="4" nzMd="4" nzOffset="8">
        <span class="grand-total">{{ getRevenueText(cost) }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tplInvoiceCreateWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData" class="grey f13" style="font-style: italic">Created {{displayInfo.createdWhen}} by 
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      class="grey"
      target="_blank">
      {{displayInfo.createdBy}}</a>
  </span>
</ng-template>
<ng-template #tplInvoiceSentWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="!hasData" class="grey f13" style="font-style: italic">not sent yet</span>
  <span *ngIf="hasData" class="grey f13" style="font-style: italic">sent {{displayInfo.createdWhen}} by 
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      class="grey"
      target="_blank">
      {{displayInfo.createdBy}}</a>
  </span>
</ng-template>