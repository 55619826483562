<div class="bottom10">
    Please confirm you want to put {{ type }} {{ warpId }} on hold.
</div>
<div class="bottom10">
    Need reviews:
    <div>
        <label nz-checkbox [(ngModel)]="reviewStatus.PICKUP_TIME">Pickup Time</label>
    </div>
    <div>
        <label nz-checkbox [(ngModel)]="reviewStatus.PICKUP_LOCATION">Pickup Location</label>
    </div>
    <div>
        <label nz-checkbox [(ngModel)]="reviewStatus.ITEM">Shipping Item</label> (pallet count)
    </div>
    <div>
        <label nz-checkbox [(ngModel)]="reviewStatus.DROPOFF_TIME">Dropoff Time</label>
    </div>
    <div>
        <label nz-checkbox [(ngModel)]="reviewStatus.DROPOFF_LOCATION">Dropoff Location</label>
    </div>
</div>
<div>
    <label>Note</label>
</div>
<div>
    <textarea nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }" [(ngModel)]="note"></textarea>
</div>